import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Columns = makeShortcode("Columns");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Columns left="Thiele" mdxType="Columns">
      <h5>{`Wir lieben IT – und das spüren unsere Kunden! Der Schutz von Investitionen, vertraulichen Daten und Dokumenten liegt unseren Mitarbeitern bei Hitabis genauso am Herzen wie eine zuverlässige und transparente Zusammenarbeit.`}</h5>
      <p>{`Sicherung und Erhöhung der Datenqualität unserer Kunden ist immer ein Schwerpunkt unserer Beratungsleistungen. Hitabis verfügt für alle Fragen zur Betreuung, Wartung und Weiterentwicklung von IT-Systemen und zur Umsetzung von IT-Projekten über erfahrene und kompetente Mitarbeiter.`}</p>
      <h2>{`Wir bieten:`}</h2>
      <ul>
        <li parentName="ul">{`Software-Architekturberatung`}</li>
        <li parentName="ul">{`Methodisches Projektmanagement`}</li>
        <li parentName="ul">{`Qualitätssicherung und Testuntersstützung`}</li>
        <li parentName="ul">{`Konfigurationsmanagement`}</li>
        <li parentName="ul">{`Reviews - vom Architekturkonzept bis zum Codereview`}</li>
        <li parentName="ul">{`Workshops, Schulungen & Coachings`}</li>
        <li parentName="ul">{`Performance-Analyse`}</li>
      </ul>
    </Columns>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      